// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/builds/hudub-com/hudub-com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("/builds/hudub-com/hudub-com/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-attributions-js": () => import("/builds/hudub-com/hudub-com/src/pages/attributions.js" /* webpackChunkName: "component---src-pages-attributions-js" */),
  "component---src-pages-contact-js": () => import("/builds/hudub-com/hudub-com/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-developers-index-js": () => import("/builds/hudub-com/hudub-com/src/pages/developers/index.js" /* webpackChunkName: "component---src-pages-developers-index-js" */),
  "component---src-pages-index-js": () => import("/builds/hudub-com/hudub-com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("/builds/hudub-com/hudub-com/src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-page-2-js": () => import("/builds/hudub-com/hudub-com/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/hudub-com/hudub-com/.cache/data.json")

